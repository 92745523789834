var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c9bd87a4a82ad24eaa13567389b6f208c38fa89b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const enableInDevelopment = false;

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://b6f92ecc183943caa66a17970e39c9d3@o158945.ingest.sentry.io/5857394';

if (process.env.ENVIRONMENT === 'production' || enableInDevelopment) {
  Sentry.init({
    dsn: SENTRY_DSN,
    sanitizeKeys: [
      /_token$/,
      /password/i,
      /annual_income_usd_max/i,
      /total_investible_assets_usd_min/i,
      /total_investible_assets_usd_max/i,
      /requested_change_ssn/i,
      /is_permanent_resident/i,
      /birth_country/i,
      /green_card_number/i,
      /green_card_expiration_date/i,
      /visa_type/i,
      /visa_number/i,
      /visa_expiration_date/i,
      /phone_numbers/i,
      /annual_income_usd_min/i,
      /firm_name/i,
      /is_affiliated_exchange_or_finra/i,
      /political_organization/i,
      /immediate_family/i,
      /is_politically_exposed/i,
      /company_symbols/i,
      /is_control_person/i,
      /citizenship_country/i,
      /date_of_birth/i,
      /social_security_number/i,
      /accounts/i,
      /margin_call_amount/i,
      /funds_available/i,
      /amount1/i,
      /amount2/i,
      /amount/i,
      /funding_operation/i,
      /apex_ach_transfer_id/i,
      /bank_routing_number/i,
      /bank_account_type/i,
      '/bank_account/i',
    ],
    autoBreadcrumbs: {
      xhr: false, // XMLHttpRequest
      console: true, // console logging
      dom: true, // DOM interactions, i.e. clicks/typing
      location: true, // url changes, including pushState/popState
      sentry: true, // sentry events
    },
    environment: process.env.ENVIRONMENT === 'production' ? 'production' : 'development',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
